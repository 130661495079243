var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"0883e35f309491fbfc9ffdaa5d5d29acbcb99228"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

if (
  process.env.NEXT_PUBLIC_SENTRY_DSN &&
  process.env.NODE_ENV === 'production'
) {
  const environment =
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
      ? 'production'
      : process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'staging'
        ? 'staging'
        : 'preview';

  if (localStorage.getItem('enable-logger')) {
    // eslint-disable-next-line no-console
    console.log('Sentry Environment:', environment);
  }

  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    // integrations: [new BrowserTracing()],
    tracesSampleRate: 0.05,
    environment,
  });
}
