import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgCalendarCheck = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 24 24"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <g id="calendar-check">
      <path
        id="Vector"
        d="M19.5 3H17.25V2.25C17.25 2.05109 17.171 1.86032 17.0303 1.71967C16.8897 1.57902 16.6989 1.5 16.5 1.5C16.3011 1.5 16.1103 1.57902 15.9697 1.71967C15.829 1.86032 15.75 2.05109 15.75 2.25V3H8.25V2.25C8.25 2.05109 8.17098 1.86032 8.03033 1.71967C7.88968 1.57902 7.69891 1.5 7.5 1.5C7.30109 1.5 7.11032 1.57902 6.96967 1.71967C6.82902 1.86032 6.75 2.05109 6.75 2.25V3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM15.9056 12.5306L11.4056 17.0306C11.336 17.1004 11.2533 17.1557 11.1622 17.1934C11.0712 17.2312 10.9736 17.2506 10.875 17.2506C10.7764 17.2506 10.6788 17.2312 10.5878 17.1934C10.4967 17.1557 10.414 17.1004 10.3444 17.0306L8.09437 14.7806C7.95364 14.6399 7.87458 14.449 7.87458 14.25C7.87458 14.051 7.95364 13.8601 8.09437 13.7194C8.23511 13.5786 8.42598 13.4996 8.625 13.4996C8.82402 13.4996 9.01489 13.5786 9.15563 13.7194L10.875 15.4397L14.8444 11.4694C14.9141 11.3997 14.9968 11.3444 15.0878 11.3067C15.1789 11.269 15.2765 11.2496 15.375 11.2496C15.4735 11.2496 15.5711 11.269 15.6622 11.3067C15.7532 11.3444 15.8359 11.3997 15.9056 11.4694C15.9753 11.5391 16.0306 11.6218 16.0683 11.7128C16.106 11.8039 16.1254 11.9015 16.1254 12C16.1254 12.0985 16.106 12.1961 16.0683 12.2872C16.0306 12.3782 15.9753 12.4609 15.9056 12.5306ZM4.5 7.5V4.5H6.75V5.25C6.75 5.44891 6.82902 5.63968 6.96967 5.78033C7.11032 5.92098 7.30109 6 7.5 6C7.69891 6 7.88968 5.92098 8.03033 5.78033C8.17098 5.63968 8.25 5.44891 8.25 5.25V4.5H15.75V5.25C15.75 5.44891 15.829 5.63968 15.9697 5.78033C16.1103 5.92098 16.3011 6 16.5 6C16.6989 6 16.8897 5.92098 17.0303 5.78033C17.171 5.63968 17.25 5.44891 17.25 5.25V4.5H19.5V7.5H4.5Z"
        fill={props.color}
      />
    </g>
  </svg>
);
export default SvgCalendarCheck;
